<template>
  <div>
    <div class="banner">
      <el-carousel :interval="5000">
        <el-carousel-item>
          <img src="../assets/images/top-banner1.jpg" alt />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/images/top-banner2.jpg" alt />
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/images/top-banner3.jpg" alt />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="container" :style="height" style="width:100%">
      <section>
        <h2 class="text-c">解锁母语封印 激活语言本能</h2>

        <div class="text-c section-content" style="position:relative">
          <img class="mb10" src="../assets/images/banner1.png" alt />
          <p
            style="position:absolute; font-size:30px; left:50%; top:50%; transform: translate(-50%, -50%); color: white;"
          >重温用语言认知世界的惊奇之旅</p>
          <p class="mb10">不论你是英语零基础，还是已经在海外生活多年，如果面临英语方面的障碍，</p>
          <p class="mb20">都只需要跟随我们的课程，回到所有语言开始的地方。</p>
          <p>适用人群：15岁以上成人</p>
        </div>
      </section>
      <section>
        <h2 class="m-center text-c mb20">课程体系</h2>
        <p class="m-center mb10">课程体系完全根据人类语言发展阶段而设计，从牙牙学语的语音关键期，到开始进行语言表达，再到以语言为工具去认知和探索世界。</p>
        <p class="m-center mb20">我们将在这三门先后衔接的基础课程中，重温用语言认知世界的惊奇之旅。</p>
        <ul class="flex-outside m-center course-list mt20">
          <li>
            <h2>语音基础-觉醒</h2>
            <div>
              <img src="../assets/images/course1.png" alt class="mb20" />
              <p class="text-c mb20">唤醒本体动觉，恢复语音本能</p>
              <p class="text-c mb20">12周，每周5课</p>
              <p class="text-c price">$400</p>
              <a href="javascript:void(0)" id="3" @click="showDetail(3)">了解详情</a>
            </div>
          </li>
          <li>
            <h2>语义基础-重生</h2>
            <div>
              <img src="../assets/images/course2.png" alt class="mb20" />
              <p class="text-c mb20">深入意义构造，建立语法思维</p>
              <p class="text-c mb20">24周，每周5课</p>
              <p class="text-c price">$800</p>
              <a href="javascript:void(0)" id="7" @click="showDetail(7)">了解详情</a>
            </div>
          </li>
          <li>
            <h2>语义进阶-征程</h2>
            <div>
              <img src="../assets/images/course3.png" alt class="mb20" />
              <p class="text-c mb20">用英语开启认知世界之旅</p>
              <p class="text-c mb20">12周，每周5课</p>
              <p class="text-c price">$800</p>
<!--              <a href="#">了解详情</a>-->
              <a href="javascript:void(0)" id="8" @click="showDetail(8)">了解详情</a>
            </div>
          </li>
          <li>
            <div>
              <img src="../assets/images/pre-course1.png" alt class="mb20" />
              <p class="text-c mb20">语音基础预备课</p>
              <p class="text-c mb20">1周，每周5课</p>
              <p class="text-c price">$1</p>
              <a href="javascript:void(0)" id="4" @click="showDetail(4)">了解详情</a>
            </div>
          </li>
          <li>
            <div>
              <img src="../assets/images/pre-course2.png" alt class="mb20" />
              <p class="text-c mb20">语义基础预备课</p>
              <p class="text-c mb20">1周，每周5课</p>
              <p class="text-c price">$1</p>
              <a href="javascript:void(0)" id="6" @click="showDetail(6)">了解详情</a>
            </div>
          </li>
          <li>
            <div>
              <img src="../assets/images/pre-course3.png" alt class="mb20" />
              <p class="text-c mb20">语义进阶预备课</p>
              <p class="text-c mb20">1周，每周5课</p>
              <p class="text-c price">$1</p>
              <a href="javascript:void(0)" id="9" @click="showDetail(9)">了解详情</a>
            </div>
          </li>
        </ul>
      </section>

      <section>
        <h2>讨论区</h2>
        <p class="mt10 mb20">不论是刚刚注册，还是在进行课程学习，都可以在相应的讨论区内进行交流</p>
        <div class="forum-list w1200">
          <div class="new-forum" @click="toForum(6)">
            <h3>新手讨论区</h3>
            <div>
              <img src="../assets/images/forum-newbie.png" alt />
              <p>
                <span>新手答疑</span>
                <span>软件使用</span>
              </p>
              <p>
                <span>课程介绍</span>
                <span>购买咨询</span>
              </p>
            </div>
          </div>
          <div class="course-forum">
            <h3>课程讨论区</h3>
            <ul>
              <li @click="toForum(4)">
                <img src="../assets/images/forum-course1.png" alt />
                <span>语音基础-讨论区</span>
              </li>
              <li @click="toForum(9)">
                <img src="../assets/images/forum-course2.png" alt />
                <span>语义基础-讨论区</span>
              </li>
              <li class="comingsoon">
                <img src="../assets/images/forum-course3.png" alt />
                <span>语义进阶-讨论区</span>
              </li>
             <!-- <li @click="toForum(5)">
                <img src="../assets/images/forum-pre-course1.png" alt />
                <span>语音基础预备课-讨论区</span>
              </li>
              <li @click="toForum(8)">
                <img src="../assets/images/forum-pre-course2.png" alt />
                <span>语义基础预备课-讨论区</span>
              </li>
              <li class="comingsoon">
                <img src="../assets/images/forum-pre-course3.png" alt />
                <span>语义进阶预备课-讨论区</span>
              </li>-->
            </ul>
          </div>
        </div>
      </section>
      <!--section>
        <h2>了解更多，点击进入博客</h2>
        <ul class="blog-list w1200">
          <li>
            <img src="../assets/images/blog-img.png" alt />
            <p>内容简介内容简介内容简介内容简介</p>
            <p>内容简介内容简介内容简介内容简介</p>
          </li>
          <li>
            <img src="../assets/images/blog-img.png" alt />
            <p>内容简介内容简介内容简介内容简介</p>
            <p>内容简介内容简介内容简介内容简介</p>
          </li>
          <li>
            <img src="../assets/images/blog-img.png" alt />
            <p>内容简介内容简介内容简介内容简介</p>
            <p>内容简介内容简介内容简介内容简介</p>
          </li>
          <li>
            <img src="../assets/images/blog-img.png" alt />
            <p>内容简介内容简介内容简介内容简介</p>
            <p>内容简介内容简介内容简介内容简介</p>
          </li>
        </ul>
      </section-->
      <section>
        <h2>关于我们</h2>
        <div class="w1200 aboutus-wraper">
          <p>Exponential Learning LLC是一家设立在美国怀俄明州的小型初创企业。致力于科学学习理论的研究和产品化开发。</p>
          <p class="mt20">Exponential English（EXPENGLISH）面向全球的英语学习者，是我们的第一个产品化项目，现已推出中文版</p>
          <div>
            <p>Exponential Learning LLC</p>
            <p>Street: 30 N Gould St Ste R</p>
            <p>City: Sheridan</p>
            <p>State: Wyoming</p>
            <p>Zip Code: 82801</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
// pc端首页轮播图,pc端首页获取课程相关信息
import { lunbo, list } from "@/assets/js/api";
export default {
  name: "home",
  data() {
    return {
      height: { height: "" },
      type: 1,
      lunboPic: 3,
      imgBasUrl: this.imgBasUrl,
      itemlist: []
    };
  },
  methods: {
    selectType(type) {
      this.type = type;
    },
    toForum(themeId) {
      sessionStorage.setItem("themeId", themeId);
      this.$router.push({
        name: "forum",
        params: {
          id: themeId
        }
      });
    },
    // 可视区域高度
    getHight() {
      this.height.height = window.innerHeight - 180 + "px";
    },
    lunboList: function() {
      lunbo({}).then(result => {
        this.lunboPic = result.list;
      });
    },
    coursecont: function() {
      list({}).then(result => {
        this.itemlist = result.list;
      });
    },
    showDetail(id) {
      localStorage.setItem("courseId", id);
      this.$router.push({
        name: "coursedetails",
        params: {
          id: id
        }
      });
    },
    courseClick(courseId) {
      // let token = localStorage.getItem("token");
      localStorage.setItem("courseId", courseId);
      this.$router.push("/coursedetails");
      // if (token) {
      //   console.log(1);
      //   this.$router.push("/coursedetails");
      // } else {
      //   console.log(2);
      //   this.$message.warning("您还未登录，请先登录");
      // }
    }
  },
  mounted: function() {
    //this.lunboList();
    //this.coursecont();
  },
  created() {
    this.getHight();
    window.addEventListener("resize", this.getHight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  }
};
</script>
<style lang="scss" scoped>
.course-forum li,
.course-list li {
  list-style: none;
}
</style>
